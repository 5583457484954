import Routes from './routers/Routes';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/navigation/navigation.min.css';
import "./styles/misc-animations.css";

//Modal Video
import 'react-modal-video/scss/modal-video.scss';

const App = () => {
    return (
        <>
            <Routes/>
        </>
    );
};

export default App;
