import React, {useEffect} from 'react';
import PageMeta from '../../components/common/PageMeta';
import Layout from '../../layout/Layout';

const HomePage = () => {
    useEffect(() => {
        setTimeout(() => {
            //window.scrollTo(0, 0);
        }, 250)
    });

    return (
        <Layout>
            <PageMeta
                title='SK Mining - Ledende mining leverandør'
            />
            <div className="bg-white mt-10 pt-10 flex min-vh-100">
                <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 mt-5 pt-60">
                    <div className="text-center">
                        <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">Kjære kunde</h2>
                        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                            SK Mining er under omorganisering og det blir arbeidet med en ny nettbutikk. Dersom du har
                            bestilt noe med oss ber vi deg lagre ordrebekreftelsen du har fått på e-post.
                        </p>
                        <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
                            Vi beklager på det sterkeste det inntrufne og vil arbeide så raskt vi kan med å få en ny
                            butikk
                            på plass, og vil selvsagt ha gode tilbud når vi er tilbake.
                            <br/>
                            Frem til en omorganisering er på plass vil butikken være stengt.
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default HomePage;
