import React, {Component} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import HomePage from "../pages/homepage/HomePage";

class Routes extends Component {
    render() {
        return (
            <>
                <Router>
                    <Switch>
                        <Route exact path="/" component={HomePage}/>
                    </Switch>
                </Router>
            </>
        );
    }
}

export default Routes;
