import React from 'react';
import {Helmet} from 'react-helmet';

const PageMeta = ({title, description = "hi"}) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="og:title" content={title}/>
            <meta name="description" content={description}/>
            <meta name="og:description" content={description}/>
        </Helmet>
    );
};

export default PageMeta;
